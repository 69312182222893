<template>
  <ion-page class="ion-page invitation-editor-page">
    <ion-content :fullscreen="false">
      <editor-canvas></editor-canvas>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent
} from "@ionic/vue";
import EditorCanvas from "@/components/EditorCanvas.vue";

export default {
  name: 'InvitationEditor',
  components: {
    EditorCanvas,
    IonPage,
    IonContent
  }
};
</script>
